var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-col w-full mt-4" },
    _vm._l(_vm.extraGroupsExtended, function (extraGroup, index) {
      return _c(
        "div",
        { key: index },
        [
          _c("span", {}, [
            _vm._v(_vm._s(extraGroup.type + " - " + extraGroup.name)),
          ]),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            refInFor: true,
            staticClass: "ag-theme-material mb-10",
            attrs: {
              components: _vm.grids[index].cellRendererComponents,
              columnDefs: _vm.grids[index].columnDefs,
              defaultColDef: _vm.grids[index].defaultColDef,
              rowData: extraGroup.extras,
              gridOptions: _vm.grids[index].gridOptions,
              domLayout: "autoHeight",
              rowSelection: "single",
              pagination: false,
              suppressPaginationPanel: true,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }