var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-row" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: {
              label: "Supplier Name",
              value: _vm.itemData && _vm.itemData.Supplier.Name,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "vx-col w-1/3" }),
      _c("div", { staticClass: "vx-col w-1/3" }, [
        !_vm.isHotlist
          ? _c("div", { staticClass: "vx-row mb-2" }, [
              _c("dl", { staticClass: "vx-col w-2/5 font-semibold" }, [
                _vm._v("Booking Reference"),
              ]),
              _c(
                "dd",
                {
                  staticClass: "vx-col w-3/5",
                  class: {
                    "booking-ref-strike-through": _vm.orderItem.itemStatus == 6,
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.orderItem.itemBookingRef == null
                        ? _vm.orderdata.orderStatus
                        : _vm.orderItem.itemBookingRef
                    )
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: { label: "Supplier Price", value: _vm.supplierPrice },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: { label: "Rules Applied", value: _vm.rulesApplied },
          }),
        ],
        1
      ),
      _vm.orderItem.itemStatus == 6
        ? _c(
            "div",
            { staticClass: "vx-col w-1/3" },
            [
              _c("travelify-display-field", {
                attrs: {
                  label: "Cancel Reference",
                  value: _vm.orderItem.itemCancelRef,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: { label: "Selling Price", value: _vm.sellingPrice },
          }),
        ],
        1
      ),
      _vm.orderItem.itemStatus == 6
        ? _c("div", { staticClass: "vx-col w-1/3" }, [_c("div")])
        : _vm._e(),
      _vm.orderItem.itemStatus == 6
        ? _c(
            "div",
            { staticClass: "vx-col w-1/3" },
            [
              _c("travelify-display-field", {
                attrs: {
                  label: "Cancel Reason",
                  value: _vm.orderItem.itemCancelReason,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "vx-col w-1/3" }, [
        _vm.orderItem.itemDeadline
          ? _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("travelify-display-field", {
                  attrs: {
                    label: "Payment/Ticketing Deadline",
                    value: _vm.orderItem.itemDeadline,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.orderItem.itemPayNowPrice
          ? _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("travelify-display-field", {
                  attrs: {
                    label: "Pay Now Price",
                    value: _vm.orderItem.itemPayNowPrice,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("vs-divider", { staticClass: "mx-4" }),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: { label: "Policy Name", value: _vm.itemData.PolicyName },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: { label: "Quote", value: _vm.itemData.QuoteType },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: { label: "Cover", value: _vm.itemData.CoverType },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: { label: "Region", value: _vm.itemData.Region },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: { label: "Start Date", value: _vm.startDate },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: { label: "End Date", value: _vm.endDate },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: {
              label: "Total",
              value: (Math.round(_vm.total * 100) / 100).toFixed(2),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: {
              label: "Tax",
              value: (Math.round(_vm.tax * 100) / 100).toFixed(2),
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("travelify-display-field", {
            attrs: { label: "Currency", value: _vm.itemData.Pricing.Currency },
          }),
        ],
        1
      ),
      _vm.itemData && _vm.itemData.Travellers
        ? _c("div", { staticClass: "vx-col w-full mt-4 mb-0" }, [
            _c("span", { staticClass: "font-semibold" }, [
              _vm._v("Insured Travellers:"),
            ]),
          ])
        : _vm._e(),
      _c("vs-divider", { staticClass: "mx-4 my-1" }),
      _c(
        "div",
        { staticClass: "vx-col w-full" },
        [
          _vm.itemData && _vm.itemData.Travellers
            ? _c("passenger-list", {
                attrs: { travellers: _vm.itemData.Travellers },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._m(1),
      _c("vs-divider", { staticClass: "mx-4 my-1" }),
      _vm._l(_vm.policyList, function (policy, index) {
        return _c("div", { key: index, staticClass: "vx-col w-full mt-2" }, [
          _c("div", { staticClass: "text-sm" }, [_vm._v(_vm._s(policy.Title))]),
          _c("div", { staticClass: "mt-1 mb-2" }, [
            _vm._v(_vm._s(policy.Text)),
          ]),
        ])
      }),
      _vm.itemData.Descriptions
        ? _c("info-items", {
            staticClass: "mt-6",
            attrs: {
              infoItems: _vm.itemData.Descriptions,
              infoType: "Descriptions",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("h4", { staticClass: "mb-4" }, [_vm._v("Insurance")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full mt-4 mb-0" }, [
      _c("span", { staticClass: "font-semibold" }, [
        _vm._v("Policy Information:"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }