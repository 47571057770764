import { render, staticRenderFns } from "./InsuranceItem.vue?vue&type=template&id=e011ada6"
import script from "./InsuranceItem.vue?vue&type=script&lang=js"
export * from "./InsuranceItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/github/workspace/src/AgendasLtd.TravioPro.Vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e011ada6')) {
      api.createRecord('e011ada6', component.options)
    } else {
      api.reload('e011ada6', component.options)
    }
    module.hot.accept("./InsuranceItem.vue?vue&type=template&id=e011ada6", function () {
      api.rerender('e011ada6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/ordermanager/components/InsuranceItem.vue"
export default component.exports