var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "carrier-logo-container" }, [
    _vm.params.data.carrierLogo
      ? _c("img", {
          staticClass: "carrier-logo-img",
          attrs: { src: _vm.params.data.carrierLogo },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }