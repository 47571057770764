var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row mb-3" }, [
        _vm._m(0),
        _c("div", { staticClass: "vx-col w-1/3" }, [
          _c("h5", [_vm._v(_vm._s(_vm.supplierSubSupplier))]),
        ]),
        _c("div", { staticClass: "vx-col w-1/3" }, [
          !_vm.isHotlist
            ? _c("div", { staticClass: "vx-row mb-2" }, [
                _c("dl", { staticClass: "vx-col w-2/5 font-semibold" }, [
                  _vm._v("Booking Reference"),
                ]),
                _c(
                  "dd",
                  {
                    staticClass: "vx-col w-3/5",
                    class: {
                      "booking-ref-strike-through":
                        _vm.orderItem.itemStatus == 6,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.orderItem.itemBookingRef == null
                          ? _vm.orderdata.orderStatus
                          : _vm.orderItem.itemBookingRef
                      )
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: { label: "Supplier Price", value: _vm.supplierPrice },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "vx-col w-1/3" }, [
          _c("div", { staticClass: "vx-row mb-2" }, [
            _c("dl", { staticClass: "vx-col w-2/5 font-semibold" }, [
              _vm._v("Rules Applied:"),
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-3/5" },
              _vm._l(_vm.itemdata.Units, function (itemUnits, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _vm._l(itemUnits.Rates, function (itemrates, index2) {
                      return _c("span", {
                        key: index2,
                        domProps: {
                          innerHTML: _vm._s(
                            itemrates.Pricing.RuleIds == undefined
                              ? ""
                              : itemrates.Pricing.RuleIds.join(", ")
                          ),
                        },
                      })
                    }),
                    index + 1 < itemUnits.Rates.length
                      ? _c("span", [_vm._v(", ")])
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
          ]),
        ]),
        _vm.orderItem.itemStatus == 6
          ? _c(
              "div",
              { staticClass: "vx-col w-1/3" },
              [
                _c("travelify-display-field", {
                  attrs: {
                    label: "Cancel Reference",
                    value: _vm.orderItem.itemCancelRef,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: { label: "Selling Price", value: _vm.sellingPrice },
            }),
          ],
          1
        ),
        _vm.orderItem.itemStatus == 6
          ? _c("div", { staticClass: "vx-col w-1/3" }, [_c("div")])
          : _vm._e(),
        _vm.orderItem.itemStatus == 6
          ? _c(
              "div",
              { staticClass: "vx-col w-1/3" },
              [
                _c("travelify-display-field", {
                  attrs: {
                    label: "Cancel Reason",
                    value: _vm.orderItem.itemCancelReason,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "vx-col w-1/3" }, [
          _vm.orderItem.itemDeadline
            ? _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("travelify-display-field", {
                    attrs: {
                      label: "Payment/Ticketing Deadline",
                      value: _vm.orderItem.itemDeadline,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.orderItem.itemPayNowPrice
            ? _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("travelify-display-field", {
                    attrs: {
                      label: "Pay Now Price",
                      value: _vm.orderItem.itemPayNowPrice,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("ratings-table", { attrs: { itemdata: _vm.itemdata } }),
      _vm.itemdata.Descriptions
        ? _c("info-items", {
            staticClass: "mt-6",
            attrs: { infoItems: _vm.itemdata.Descriptions },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-1/3" }, [
      _c("h5", [_vm._v("Accommodation")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }