var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row mb-2" }, [
    _c("dl", { staticClass: "vx-col w-2/5 font-semibold" }, [
      _vm._v(_vm._s(_vm.label + ":")),
    ]),
    !_vm.html
      ? _c("dd", { staticClass: "vx-col w-3/5" }, [_vm._v(_vm._s(_vm.value))])
      : _vm._e(),
    _vm.html
      ? _c("span", {
          staticClass: "vx-col w-3/5",
          domProps: { innerHTML: _vm._s(_vm.html) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }