var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: {
                label: "Supplier Name",
                value: _vm.itemdata && _vm.itemdata.Supplier.Name,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "vx-col w-1/3" }),
        _c("div", { staticClass: "vx-col w-1/3" }, [
          !_vm.isHotlist
            ? _c("div", { staticClass: "vx-row mb-2" }, [
                _c("dl", { staticClass: "vx-col w-2/5 font-semibold" }, [
                  _vm._v("Booking Reference"),
                ]),
                _c(
                  "dd",
                  {
                    staticClass: "vx-col w-3/5",
                    class: {
                      "booking-ref-strike-through":
                        _vm.orderItem.itemStatus == 6,
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.orderItem.itemBookingRef == null
                          ? _vm.orderdata.orderStatus
                          : _vm.orderItem.itemBookingRef
                      )
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: { label: "Supplier Price", value: _vm.supplierPrice },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: { label: "Rules Applied", value: _vm.rulesApplied },
            }),
          ],
          1
        ),
        _vm.orderItem.itemStatus == 6
          ? _c(
              "div",
              { staticClass: "vx-col w-1/3" },
              [
                _c("travelify-display-field", {
                  attrs: {
                    label: "Cancel Reference",
                    value: _vm.orderItem.itemCancelRef,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("travelify-display-field", {
              attrs: { label: "Selling Price", value: _vm.sellingPrice },
            }),
          ],
          1
        ),
        _vm.orderItem.itemStatus == 6
          ? _c("div", { staticClass: "vx-col w-1/3" }, [_c("div")])
          : _vm._e(),
        _vm.orderItem.itemStatus == 6
          ? _c(
              "div",
              { staticClass: "vx-col w-1/3" },
              [
                _c("travelify-display-field", {
                  attrs: {
                    label: "Cancel Reason",
                    value: _vm.orderItem.itemCancelReason,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "vx-col w-1/3" }, [
          _vm.orderItem.itemDeadline
            ? _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("travelify-display-field", {
                    attrs: {
                      label: "Payment/Ticketing Deadline",
                      value: _vm.orderItem.itemDeadline,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.orderItem.itemPayNowPrice
            ? _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("travelify-display-field", {
                    attrs: {
                      label: "Pay Now Price",
                      value: _vm.orderItem.itemPayNowPrice,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "table-grid-item-col-1" }, [
        _c("div", { staticClass: "table-grid-item-col-3 order-list mt-5" }, [
          _c("div", { staticClass: "img-field" }, [
            _c("img", {
              staticClass: "card-img-top",
              attrs: { src: _vm.itemdata.Media[0].Url, alt: "Card image cap" },
            }),
          ]),
          _c("div", { staticClass: "table-grid-item-col-1-pd" }, [
            _c("div", {
              staticClass: "label-field",
              domProps: { innerHTML: _vm._s(_vm.itemdata.Name) },
            }),
            _c("div", {
              staticClass: "text-field",
              domProps: { innerHTML: _vm._s(_vm.itemdata.Location.Name) },
            }),
            _c("div", {
              staticClass: "text-field",
              domProps: { innerHTML: _vm._s(_vm.itemdata.Location.PostalCode) },
            }),
          ]),
          _c("div", { staticClass: "table-grid-item-col-1-pd" }, [
            _c("div", {
              staticClass: "label-field",
              domProps: { innerHTML: _vm._s(_vm.itemdata.Options[0].Name) },
            }),
            _c("div", {
              staticClass: "text-field",
              domProps: {
                innerHTML: _vm._s(_vm.itemdata.Options[0].Description),
              },
            }),
          ]),
        ]),
      ]),
      _vm.itemdata.Descriptions
        ? _c("info-items", {
            staticClass: "mt-6",
            attrs: { infoItems: _vm.itemdata.Descriptions },
          })
        : _vm._e(),
      _vm.itemdata.Guests && _vm.itemdata.Guests.length > 0
        ? _c("div", { staticClass: "vx-col w-full mt-6" }, [
            _c("span", { staticClass: "font-semibold" }, [
              _vm._v("Travellers:"),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "vx-col w-full" },
        [
          _vm.itemdata.Guests && _vm.itemdata.Guests.length > 0
            ? _c("passenger-list", {
                attrs: { travellers: _vm.itemdata.Guests },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vx-col w-full" }, [
      _c("h4", { staticClass: "mb-4" }, [_vm._v("Tickets & Attractions")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }