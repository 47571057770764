var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-col w-full", staticStyle: { width: "100%" } },
    [
      _vm.infoItems && _vm.infoItems.length > 0
        ? _c("div", { staticClass: "vx-col w-full mt-4" }, [
            _c("span", { staticClass: "font-semibold" }, [
              _vm._v("Additional Information:"),
            ]),
          ])
        : _vm._e(),
      _vm.infoItems && _vm.infoItems.length > 0
        ? _c(
            "div",
            { staticClass: "vx-col w-full" },
            [_c("vs-divider", { staticClass: "mb-0" })],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "info-items-container" },
        _vm._l(_vm.infoItems, function (infoItem, index) {
          return _c("div", { key: index }, [
            _c("div", { staticClass: "p-5" }, [
              _c("h6", [_vm._v(_vm._s(infoItem.Title))]),
              _c("p", { staticClass: "text-sm" }, [
                _vm._v(_vm._s(infoItem.Type)),
              ]),
              _c("p", {
                staticClass: "mt-2",
                domProps: { innerHTML: _vm._s(infoItem.Text) },
              }),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }